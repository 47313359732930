.image-stat {
    margin: 0 0 0.75rem 0;
    font-size: 1.125rem;
    line-height: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: flex-start;
    padding-right: 1rem;
}

.image-stat strong {
    font-weight: 400;
    color: #666;
    margin-right: 0.5rem;
}

.image-stat span {
    font-weight: 400;
    color: #000;
}

.image-stat em {
    font-weight: 400;
    color: #444;
    font-size: 0.875rem;
    font-style: italic;
    margin-left: 0.25rem;
}

@media screen and (max-width: 50rem) {
    .image-stat {
        font-size: 0.875rem;
    }

    .image-stat em {
        font-size: 0.75rem;
    }
}