.header {
    background: rgba(255, 255, 255, 0.05);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.5rem;
    padding: 1rem;
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
    box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.6);
    filter: blur(0.4);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
}

.header h1 {
    font-weight: 500;
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    line-height: 1;
    color: #f6f6f6;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}

.header button {}