main {
    position: relative;
    padding: 1.5rem 1.5rem 4rem 1.5rem;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
}

main[data-status="loaded"] {
    display: block;
}

main[data-status="waiting"] {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}