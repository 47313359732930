.gif-section {
    padding: 1rem;
    background: #fff;
    margin-bottom: 1rem;
}

.gif-image {
    border: 1px #000 solid;
    background-image: url(data:image/gif;base64,R0lGODlhEAAQAIABAH9/f7OzsywAAAAAEAAQAAACH4xvoKuIzNyBSyYKbMDZcv15HAaSIlWiJ5Sya/RWVgEAOw==);
    margin-right: 2rem;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.cell {
    flex-basis: calc(50% - 1rem);
    flex-grow: 1;
}

.cell-full {
    flex-basis: 100%;
    flex-grow: 1;
    max-width: 100%;
}

@media screen and (max-width: 50rem) {

    .cell-full,
    .cell {
        flex-basis: 100%;
        flex-grow: 1;
        max-width: 100%;
    }
}

.gif-section h2 {
    font-size: 1.5rem;
    font-weight: 300;
    color: #222;
    margin: 0;
}

.gif-section h2 small {
    font-size: 1rem;
    font-weight: 400;
    color: #666;
    font-family: 'Roboto Mono', monospace;
}

.gif-section h3 {
    font-size: 1.125rem;
    font-weight: 500;
    color: #444;
    margin: 0.25rem 0 0.75rem 0;
    text-decoration: underline;
}

.gif-section p.meta {
    font-size: 0.75rem;
    font-weight: 300;
    font-family: 'Roboto Mono', monospace;
    color: #000;
    margin: 0;
}

.gif-frames-navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.gif-frames-navigation a {
    border: 1px #777 solid;
    color: #555;
    padding: 0.375rem;
    font-size: 0.875rem;
    line-height: 1;
    text-align: center;
    font-weight: 400;
}

@media screen and (max-width: 50rem) {
    .gif-frames-navigation {
        gap: 0.5rem;
    }

    .gif-frames-navigation a {
        padding: 0.275rem;
        font-size: 0.75rem;
    }
}

.gif-frames-navigation a.active {
    background: #999;
    color: #fff;
    font-weight: 500;
}

.gif-frames-wrapper {
    width: calc(100% + 1rem);
    overflow: hidden;
}

.gif-frames-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    transition: transform 0.3s ease-out;
}

.missing-value {
    color: #777;
    font-style: italic;
    margin: 0;
}

.frame-color-table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.frame-color-table span {
    display: block;
    width: 4.5rem;
    height: 2rem;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    font-size: 0.75rem;
    line-height: 1;
    text-align: center;
    padding: 0.5rem;
    color: #fff;
    position: relative;
}

.frame-color-table span::before {
    content: attr(data-color);
    position: absolute;
    inset: 0;
    padding: 0.5rem;
    mix-blend-mode: hard-light;
}