.file-target {
    border-radius: 0.25rem;
    padding: 1rem;
    border: 0.125rem rgba(255, 255, 255, 0.5) solid;
    background: rgb(221, 221, 221);
    background: radial-gradient(circle, rgba(221, 221, 221, 0.25) 0%, rgba(153, 153, 153, 0.25) 100%);
    width: 50vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.75);
    overflow: hidden;
    text-align: center;
    position: relative;
}

.file-target[data-file="over"] {
    border-color: #0f2;
}

.file-target[data-file="dropped"] {
    border-color: #fff;
}

.file-target[data-file="invalid"] {
    border-color: #f20;
}

.file-target p {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
    color: #eee;
    font-weight: 300;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.file-target p strong {
    font-weight: 500;
    text-decoration: underline;
}

.file-target span {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    color: #ddd;
    font-weight: 300;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.file-target .file-input {
    position: relative;
    width: 6rem;
    height: 3rem;
}

.file-target label {
    margin: 0;
    font-size: 1rem;
    color: #eee;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    font-weight: 400;
    border-radius: 0.25rem;
    border: 1px rgba(255, 255, 255, 0.75) solid;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
    position: absolute;
    width: 6rem;
    display: block;
    z-index: 1;
}

.file-target label:hover {
    border: 1px rgba(255, 255, 255, 1) solid;
    background: rgba(255, 255, 255, 0.1);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
}

.file-target input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.file-target em {
    width: 10rem;
    position: absolute;
    top: 1rem;
    left: calc(50% - 5rem);
    text-align: center;
    color: #f20;
    font-size: 1rem;
    font-weight: 500;
    z-index: 1;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
}

@media screen and (max-width: 50rem) {
    .file-target {
        width: 75vw;
        height: 50vh;
    }
}